import { Auth0Provider } from "@auth0/auth0-react";
import AuthApp from './AuthApp';
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={"dev-9srqsm6e.us.auth0.com"}
      clientId={"piNf7OKy6EZj15nGKMM7yWFZiHJNvpjX"}
      redirectUri={window.location.origin}
      audience={`${process.env.REACT_APP_API_URL}/`}
      scope={"read:current_user update:current_user_metadata"}
    >
      <AuthApp />
    </Auth0Provider>
    <ToastContainer />
  </React.StrictMode>,
  document.getElementById('root')
);
