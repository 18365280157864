import { WSS_URL } from '../Api';

let socket;
let setSocket;

let heartbeat;
let heartCounter = 0;
const heartInterval = 15650;

export const initiateSocketConnection = (socketCallback, token) => {
	socket = new WebSocket(`${WSS_URL}?token=${token}`);
    setSocket = socketCallback;
    //console.log(`Connecting socket...`);

    socket.onopen = () => {
        //console.log(`Socket connected`);
        setSocket(true);
    };

    socket.onerror = (err) => {
        console.log(err);
    };

    socket.onmessage = (m) => {
        //console.log(m);

        let data = JSON.parse(m.data);
        if (data.event_type === "pong") {
            heartCounter--;
        }
    };

    heartbeat = setInterval(() => {
        if (heartCounter > 3) {
            disconnectSocket();
        } else {
            heartCounter++;
            socket.send(JSON.stringify({data: {event_type: "ping"}}));
        }
    }, heartInterval)
}

export function addMessageHandler(func) {
    socket.addEventListener("message", func);
}

export const disconnectSocket = () => {
    //console.log('Disconnecting socket...');
    if(socket) socket.close();
    setSocket(false);
    clearTimeout(heartbeat);
}