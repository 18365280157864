import 'react-toastify/dist/ReactToastify.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-phone-number-input/style.css'

import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { addMessageHandler, disconnectSocket, initiateSocketConnection } from "./services/wss.service";

import { API } from "./Api";
import LoadingButton from "@mui/lab/LoadingButton"
import LogoutButton from "./LogoutButton";
import PhoneInput from 'react-phone-number-input'
import ReactGA from "react-ga4";
import SettingsEthernetSharpIcon from '@mui/icons-material/SettingsEthernetSharp';
import awesomePhonenumber from 'awesome-phonenumber';
import moment from "moment-timezone"
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';

function FaxApp() {

  const defaultFaxFrom = "+12675780004";

  //queue table states
  const [stateQueueTable, setQueueTable] = useState([]);
  const [updateQueueTable, setUpdateQueueTable] = useState(false);
  const [queueTableLoading, setQueueTableLoading] = useState(false);

  //stored table states
  const [stateStoredTable, setStoredTable] = useState([]);
  const [updateStoredTable, setUpdateStoredTable] = useState(false);
  const [storedTableLoading, setStoredTableLoading] = useState(false);
  const [storedTablePage, setStoredTablePage] = React.useState(0);
  const [storedTableRowsPerPage, setStoredTableRowsPerPage] = React.useState(5);

  //button states
  const [refreshList, setRefreshList] = useState([]);
  const [processList, setProcessList] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [downloadList, setDownloadList] = useState([]);


  //submit form states
  const [fileUpload, setFileUpload] = useState();
  const [faxFrom, setFaxFrom] = useState(defaultFaxFrom)
  const [faxTo, setFaxTo] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef();

  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  //socket state
  const [socketConnected, setSocketConnected] = useState(false);

  useEffect(() => {
    initiateSocketWithAuth();

    return () => {
      disconnectSocket();
    }
  }, []);

  useEffect(() => {
    getQueueData();

    getStoredData();
  }, [updateQueueTable, updateStoredTable]);

  const initiateSocketWithAuth = async () => {
    const token = await getAccessTokenSilently();

    initiateSocketConnection(setSocketConnected, token);

    addMessageHandler((e) => {
      let data = JSON.parse(e.data);
      switch (data.event_type) {
        case "fax.received": {
          toast.info(`Fax received from ${awesomePhonenumber(data.from).getNumber('national')} after ${data.duration} seconds`);
          getQueueData();
          getStoredData();
          break;
        }
        case "fax.receiving.started": {
          toast.info(`Fax receiving started from ${awesomePhonenumber(data.from).getNumber('national')}`);
          getQueueData();
          break;
        }
        case "fax.sending.started": {
          toast.info(`Started sending fax to ${awesomePhonenumber(data.to).getNumber('national')}`);
          getQueueData();
          break;
        }
        case "fax.delivered": {
          toast.info(`Fax delivered to ${awesomePhonenumber(data.to).getNumber('national')}`);
          getQueueData();
          getStoredData();
          break;
        }
        case "fax.queued": {
          toast.info(`Fax to ${awesomePhonenumber(data.to).getNumber('national')} is in the queue`);
          getQueueData();
          break;
        }
        case "fax.failed": {
          if (data.direction === 'inbound') {
            toast.error(`Fax from ${awesomePhonenumber(data.from).getNumber('national')} failed: ${data.failure_reason}`);
          }
          if (data.direction === 'outbound') {
            toast.error(`Fax to ${awesomePhonenumber(data.to).getNumber('national')} failed: ${data.failure_reason}`);
          }
          getQueueData();
          break;
        }
      }
    });
  }

  const getQueueData = async () => {
    setQueueTableLoading(true);

    const token = await getAccessTokenSilently();

    const faxes = await API.get("/api/fax/queue", { headers: { Authorization: `Bearer ${token}` } });

    setQueueTableLoading(false);

    //console.log(faxes.data);

    setQueueTable(faxes.data);
  }

  const getStoredData = async () => {
    setStoredTableLoading(true);
    const token = await getAccessTokenSilently();

    const faxes = await API.get("/api/fax/stored", { headers: { Authorization: `Bearer ${token}` } });
    setStoredTableLoading(false);

    //console.log(faxes.data);

    setStoredTable(faxes.data.sort((a, b) => {
      return a.time < b.time
    }));
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const storedEmptyRows =
    storedTablePage > 0 ? Math.max(0, (1 + storedTablePage) * storedTableRowsPerPage - stateStoredTable.length) : 0;

  const storedTableHandlePageChange = (event, newPage) => {
    setStoredTablePage(newPage);
  };

  const storedTableHandleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setStoredTableRowsPerPage(parseInt(event.target.value));
    console.log(storedTableRowsPerPage);
    setStoredTablePage(0);
  };

  const refreshLink = (async (id) => {
    console.log(id);

    try {

      const token = await getAccessTokenSilently();

      const refreshResult = await API.post(`/api/fax/queue/${id}`, {}, { headers: { Authorization: `Bearer ${token}` } });

      console.log(refreshResult);

      if (refreshResult.status === 204) {
        setUpdateQueueTable(!updateQueueTable);
      }
    } catch (e) {
      toast.error(e.message)
    } finally {
      setRefreshList(refreshList.splice(refreshList.indexOf(id), 1));
    }
  });

  const processLink = (async (id) => {
    console.log(id);

    try {
      const token = await getAccessTokenSilently();


      const processResult = await API.post(`/api/fax/queue/${id}/process`, {}, { headers: { Authorization: `Bearer ${token}` } });

      console.log(processResult);

      if (processResult.status === 204) {
        setUpdateQueueTable(!updateQueueTable);
        setUpdateStoredTable(!updateStoredTable);
      }
    } catch (e) {
      toast.error(e.message)
    } finally {
      setProcessList(processList.splice(processList.indexOf(id), 1));
    }
  });

  const deleteLink = (async (id) => {
    console.log(id);

    try {
      ReactGA.event({
        category: "fax",
        action: "delete",
        nonInteraction: false, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
      const token = await getAccessTokenSilently();

      const refreshResult = await API.delete(`/api/fax/queue/${id}`, { headers: { Authorization: `Bearer ${token}` } });

      console.log(refreshResult);

      if (refreshResult.status === 204) {
        setUpdateQueueTable(!updateQueueTable);
      }
    } catch (e) {
      toast.error(e.message)
    } finally {
      setDeleteList(deleteList.splice(deleteList.indexOf(id), 1));
    }
  });

  const downloadStored = (async (id) => {
    try {
      ReactGA.event({
        category: "fax",
        action: "download",
        nonInteraction: false, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
      const token = await getAccessTokenSilently();
      const downloadResult = await API.get(`/api/fax/stored/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      window.open(downloadResult.data, "_blank");
    } catch (e) {
      toast.error(e.message)
    } finally {
      setDownloadList(downloadList.splice(downloadList.indexOf(id), 1));
    }
  });

  const archiveStored = async (id) => {
    try {
      ReactGA.event({
        category: "fax",
        action: "archive",
        nonInteraction: false, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
      const token = await getAccessTokenSilently();
      const result = await API.delete(`/api/fax/stored/${id}`, { headers: { Authorization: `Bearer ${token}` } });

      if (result.status === 204) {
        setUpdateStoredTable(!updateStoredTable);
      }
    } catch (e) {
      toast.error(e.message)
    } finally {
      setDeleteList(deleteList.splice(deleteList.indexOf(id), 1));
    }
  }

  const sendFax = (async () => {
    try {
      setIsSubmitting(true);
      console.log(fileUpload);
      console.log(faxTo);
      ReactGA.event({
        category: "fax",
        action: "send",
        nonInteraction: false, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
      if (fileUpload !== undefined && faxTo !== undefined && faxFrom !== undefined) {
        const fd = new FormData();
        const token = await getAccessTokenSilently();
        fd.append("to", faxTo);
        fd.append("from", faxFrom);
        fd.append("contents", fileUpload, fileUpload.name)
        const sendResult = await API.post("/api/send", fd, { headers: { Authorization: `Bearer ${token}` } });
        if (sendResult.status === 202) {
          toast.info("Fax uploaded...");
          setFaxTo("");
          setFileUpload("");
          setFaxFrom(defaultFaxFrom)
          formRef.current.reset();
          setUpdateQueueTable(!updateQueueTable);
        }
        console.log(sendResult);
      } else {
        toast.warning("Fill out the fields");
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsSubmitting(false);
    }

  });

  //console.log(socketConnected)

  return isAuthenticated && !isLoading && (
    <div>
      <header className="App-header">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h5">Fax Queue</Typography>
          </Grid>
          <Grid item xs={6}>
            {isAuthenticated && <Typography align="right"><LogoutButton /></Typography>}
            {socketConnected && <Typography align="right"><SettingsEthernetSharpIcon /> </Typography>}
          </Grid>
        </Grid>
        <LinearProgress sx={{ visibility: queueTableLoading ? 'visible' : 'hidden' }} />
      </header>
      <br />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Direction</TableCell>
              <TableCell>To</TableCell>
              <TableCell>From</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stateQueueTable.map(el => {
              return (
                <TableRow key={el.id}>
                  <TableCell>{el.status}</TableCell>
                  <TableCell>{el.direction}</TableCell>
                  <TableCell>{awesomePhonenumber(el.to).getNumber('national')}</TableCell>
                  <TableCell>{awesomePhonenumber(el.from).getNumber('national')}</TableCell>
                  <TableCell>{moment.utc(el.created_at).tz("America/New_York").format("YYYY-MM-DD | hh:mm:ss a")}</TableCell>
                  <TableCell>
                    {(el.status === 'received')
                      && <LoadingButton
                        loading={refreshList.includes(el.id)}
                        size="small"
                        sx={{ padding: "3px" }}
                        variant="contained"
                        color="warning"
                        onClick={() => { setRefreshList(old => [...old, el.id]); refreshLink(el.id); }}>Refresh</LoadingButton>}

                    {(el.status === 'received')
                      && <LoadingButton
                        loading={processList.includes(el.id)}
                        size="small"
                        sx={{ padding: "3px" }}
                        variant="contained"
                        color="secondary"
                        onClick={() => { setProcessList(old => [...old, el.id]); processLink(el.id); }}>Process</LoadingButton>}

                    <br /><br />

                    {(el.status === 'received')
                      && <Button
                        size="small"
                        sx={{ padding: "3px" }}
                        variant="contained"
                        color="primary">
                        <a style={{ textDecoration: 'none', color: 'white' }} href={el.media_url || el.stored_media_url}>Download</a></Button>}

                    {(el.status === 'received' || el.status === 'delivered' || el.status == 'failed')
                      && <LoadingButton
                        loading={deleteList.includes(el.id)}
                        size="small"
                        sx={{ padding: "3px" }}
                        variant="contained"
                        color="error"
                        onClick={() => { setDeleteList(old => [...old, el.id]); deleteLink(el.id) }}>Delete</LoadingButton>}

                  </TableCell>

                  <TableCell>{el.id}</TableCell>
                </TableRow>
              );
            })}


          </TableBody>
        </Table>
      </TableContainer>
      <hr /><br /><br />
      <header className="App-header">
        <Typography variant="h5">Stored Faxes</Typography><LinearProgress sx={{ visibility: storedTableLoading ? 'visible' : 'hidden' }} />
      </header>
      <br />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Direction</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>To</TableCell>
              <TableCell>From</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(stateStoredTable.slice(storedTablePage * storedTableRowsPerPage, storedTablePage * storedTableRowsPerPage + storedTableRowsPerPage)
            ).map(el => {
              return (
                <TableRow key={el.id}>
                  <TableCell>{el.direction}</TableCell>
                  <TableCell>{el.duration}</TableCell>
                  <TableCell>{awesomePhonenumber(el.to).getNumber('national')}</TableCell>
                  <TableCell>{awesomePhonenumber(el.from).getNumber('national')}</TableCell>
                  <TableCell>{moment.utc(el.time).tz("America/New_York").format("YYYY-MM-DD | hh:mm:ss a")}</TableCell>
                  <TableCell>
                    <LoadingButton
                      loading={downloadList.includes(el.id)}
                      size="small"
                      onClick={() => { setDownloadList(old => [...old, el.id]); downloadStored(el.id) }}
                      sx={{ padding: "3px" }}
                      variant="contained"
                      color="primary">
                      Download
                    </LoadingButton>
                    <br /><br />
                    <LoadingButton
                      loading={deleteList.includes(el.id)}
                      size="small"
                      sx={{ padding: "3px" }}
                      variant="contained"
                      color="warning"
                      onClick={() => { setDeleteList(old => [...old, el.id]); archiveStored(el.id) }}>Archive</LoadingButton>
                  </TableCell>
                  <TableCell>{el.id}</TableCell>
                </TableRow>
              );
            })}
            {storedEmptyRows > 0 && (
              <TableRow style={{ height: 53 * storedEmptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPage={storedTableRowsPerPage}
                count={stateStoredTable.length}
                rowsPerPageOptions={[1, 5, 10, 25, { label: 'All', value: -1 }]}
                page={storedTablePage}
                onPageChange={storedTableHandlePageChange}
                onRowsPerPageChange={storedTableHandleChangeRowsPerPage}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <hr /><br /><br />
      <br />
      <Grid container spacing={3} direction="column" alignItems="center" justifyContent="center">
        <header className="App-header">
          <Typography variant="h5">Send Fax</Typography>
        </header>
        <form ref={formRef} onSubmit={e => { e.preventDefault(); sendFax() }}>
          <Grid item xs={12}>
            <InputLabel id="label">Document</InputLabel>
            <FormControl>
              <Input inputProps={{ accept: "application/pdf" }} type="file" onChange={(e) => setFileUpload(e.target.files[0])} />
              <FormHelperText>File must be PDF format</FormHelperText>
            </FormControl>
          </Grid>
          <br />
          <Grid item xs={12}>
            <InputLabel id="label">From</InputLabel>
            <FormControl>
              <Select
                value={faxFrom}
                onChange={e => setFaxFrom(e.target.value)}
              >
                <MenuItem value={"+12675780004"}>(267) 578-0004</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <br />
          <Grid item xs={12}>
            <InputLabel id="label">To</InputLabel>
            <FormControl>
              <PhoneInput
                onKeyPress={e => { if (e.key === "Enter") { e.preventDefault(); sendFax(); } }}
                placeholder="Enter Fax Destination"
                value={faxTo}
                onChange={e => setFaxTo(e)}
                addInternationalOption={false}
                smartCaret={false}
                countries={["US", "CA"]}
                defaultCountry={"US"}
                inputComponent={Input}
              />
            </FormControl>
          </Grid>
          <br />
          <Grid item xs={12}>
            <Typography align="right">
              <LoadingButton loading={isSubmitting} variant="contained" color="primary" onClick={sendFax}>Send</LoadingButton>
            </Typography>
          </Grid>
        </form>
      </Grid>
    </div>
  );
}

export default FaxApp;
