import { Button } from "@mui/material";
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function LoginButton() {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useAuth0();

  return !isAuthenticated && !isLoading && (
    <Button
      variant="contained"
      type="primary"

      onClick={() => loginWithRedirect({scope: "read:current_user"})}>Log in</Button>
  );
}

export default LoginButton;