import { Grid, Typography } from "@mui/material"

import FaxApp from "./FaxApp";
import LoginButton from "./LoginButton";
import React from "react";
import ReactGA from "react-ga4";
import { useAuth0 } from "@auth0/auth0-react";

function AuthApp() {
    ReactGA.initialize("G-XXL6TK40JJ");
    ReactGA.send("pageview");
    const { isAuthenticated, isLoading } = useAuth0();

    return (
        <div className="App">
            {!isAuthenticated && !isLoading &&
                <div>
                    <header className="App-header">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                            <Typography align="right"><LoginButton /></Typography>
                            </Grid>
                        </Grid>
                    </header>
                </div>}

            {isAuthenticated && !isLoading && <FaxApp />}
        </div>
    );
}


export default AuthApp;