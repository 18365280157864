import { Button } from "@mui/material";
import React from 'react';
import { disconnectSocket } from "./services/wss.service";
import { useAuth0 } from '@auth0/auth0-react';

function LogoutButton() {
  const {
    isAuthenticated,
    isLoading,
    logout,
  } = useAuth0();

  return isAuthenticated && !isLoading && (
    <Button 
    variant="contained"
    type="secondary"
    size="small"
    
    onClick={() => {
      disconnectSocket();
      logout({ returnTo: window.location.origin });
    }}>Log out</Button>
  );
}

export default LogoutButton;